<script setup>
// Import other components as needed
const state = reactive({
  name: "",
  messages: [
    "Generate a full lookbook with this collection, targeting Scandinavian market",
    //"Show me this summer dress on 5 different body types, with Miami beach styling",
  ],
});

const currentView = ref("UserForm");
const props = defineProps({
  modelValue: {
    type: Boolean,
    required: false,
  },
});

const emit = defineEmits(["update:modelValue"]);
const isOpen = computed({
  get: () => props.modelValue,
  set: (value) => emit("update:modelValue", value),
});
</script>

<template>
  <UModal
    v-model="isOpen"
    class="flex items-center justify-center"
    :ui="{
      width: 'w-full sm:max-w-2xl',
      shadow: '',
      background: 'bg-transparent dark:bg-transparent',
      overlay: {
        background: 'bg-black/75 dark:bg-black/75 backdrop-blur',
      },
    }"
  >
    <div class="rounded-xl dark:bg-transparent relative flex h-full w-full">
      <UForm class="space-y-4 w-full">
        <span class="text-center w-full text-white text-xs hidden"
          >GRAIN AGENTIC AI</span
        >
        <h2
          class="mx-auto text-white font-bold text-4xl relative text-center w-full"
        >
          Hi, what would you like to create?
        </h2>
        <div class="w-full h-16 absolute -ml-2 -mr-2">
          <div class="glowsparkwrapper"><div class="glowspark"></div></div>
        </div>
        <UFormGroup
          name="name"
          class="w-full gradient-border rounded-xl relative"
        >
          <UInput
            variant="none"
            v-model="state.name"
            placeholder="Enter you fashion dream here"
            size="lg"
            class="w-full"
          >
            <template #trailing>
              <span
                class="rounded-full gradient-border text-white-500 dark:text-black !text-xs !py-1 !px-3 !ml-0 !mr-0 !mt-0 inline-flex cursor-pointer"
              >
                <span>Send</span>
              </span>
            </template></UInput
          >
        </UFormGroup>
        <transition-group
          name="message"
          tag="div"
          class="text-xs text-white text-center whitespace-nowrap"
        >
          <div v-for="(message, index) in state.messages" :key="index">
            <span>{{ message }}</span>
          </div>
        </transition-group>
      </UForm>
    </div>
  </UModal>
</template>

<style scoped lang="postcss">
.message-enter-active,
.message-leave-active {
  transition: opacity 0.5s;
}
.message-enter, .message-leave-to /* .message-leave-active in <2.1.8 */ {
  opacity: 0;
}
.dragging {
  border: 2px dashed #4b5563 !important;
  background-color: #2d3748 !important;
  border-radius: 0.375rem !important;
  transition: border-color 0.2s ease !important;
}
.selected-card {
  border: 1px solid #4b5563; /* Customize the color as needed */
}
.gradient-border-anim {
  --borderWidth: 1px;
  background: #1d1f20;
  position: relative;
  border-radius: var(--borderWidth);
}
.gradient-border-anim:after {
  content: "";
  position: absolute;
  top: calc(-1 * var(--borderWidth));
  left: calc(-1 * var(--borderWidth));
  height: calc(100% + var(--borderWidth) * 2);
  width: calc(100% + var(--borderWidth) * 2);
  background: linear-gradient(
    60deg,
    #f79533,
    #f37055,
    #ef4e7b,
    #a166ab,
    #5073b8,
    #1098ad,
    #07b39b,
    #6fba82
  );
  border-radius: calc(2 * var(--borderWidth));
  z-index: -1;
  animation: animatedgradient 3s ease alternate infinite;
  background-size: 300% 300%;
}

@keyframes animatedgradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

.glowcontainer {
  width: 300px;
  height: 450px;
  background: gray;

  position: relative;
}
.glowspark {
  border-radius: 5px;
  overflow: hidden;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0px;
  left: 0px;
  filter: blur(9px);
  mix-blend-mode: multiply;
  opacity: 1;
  transition: all 0.5s;
}

.processing .glowspark {
  opacity: 0;
}

.glowspark:after {
  content: "";
  position: absolute;
  background: #fff;
  inset: 10px;
  border-radius: 5px;
}

.glowspark::before {
  content: "";
  width: 200%;

  aspect-ratio: 1;
  inset: 50% auto auto 50%;
  position: absolute;
  translate: -50% -50%;
  animation: lazy 6s infinite linear;
  background: conic-gradient(
    transparent,
    rgba(0, 0, 255, 1) 90deg,
    transparent 180deg
  );
}

@keyframes lazy {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.glowtwo {
  border-radius: 5px;
  overflow: hidden;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0px;
  left: 0px;
  filter: blur(20px);
  box-shadow: inset 0 0 50px rgba(255, 0, 255, 0.5),
    inset 0 0 100px rgba(0, 0, 255, 0.5), inset 0 0 150px rgba(255, 0, 255, 0.5);

  transition: all 0.5s;
}

.processing .glowtwo {
  box-shadow: inset 0 0 0px rgba(255, 0, 255, 0.5),
    inset 0 0 0px rgba(0, 0, 255, 0.5), inset 0 0 0px rgba(255, 0, 255, 0.5);
}

.glowtwo:before {
  content: "";
  position: absolute;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  filter: blur(20px);
  border: 15px solid;
  border-image: linear-gradient(
      90deg,
      rgba(255, 0, 255, 1),
      rgba(0, 0, 255, 1),
      rgba(255, 0, 255, 1)
    )
    10;

  transition: all 0.5s;
}

.processing .glowtwo:before {
  border: 0;
}

.glow {
  width: 800px;
  height: 450px;
  background: black;
  overflow: hidden;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0px;
  left: 0px;

  box-shadow: inset 0 0 50px rgba(255, 0, 255, 0.5),
    inset 0 0 100px rgba(0, 0, 255, 0.5), inset 0 0 150px rgba(255, 0, 255, 0.5);
}

.glow:before {
  content: "";
  position: absolute;
  top: -10px;
  left: -10px;
  right: -10px;
  bottom: -10px;
  background: linear-gradient(
    90deg,
    rgba(255, 0, 255, 1),
    rgba(0, 0, 255, 1),
    rgba(255, 0, 255, 1)
  );
  z-index: 1;
  filter: blur(30px);
}

.glow:after {
  content: "";
  position: absolute;
  top: 5%;
  left: 5%;
  right: 5%;
  bottom: 5%;
  background: black;
  filter: blur(50px);
  z-index: 2;
}

.wipe {
  transition: all 600ms cubic-bezier(0, 0.55, 0.45, 1);
}
</style>
