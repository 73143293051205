<template>
  <svg
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    version="1.1"
    viewBox="0 0 671 213"
  >
    <!-- Generator: Adobe Illustrator 29.0.0, SVG Export Plug-In . SVG Version: 2.1.0 Build 186)  -->
    <defs>
      <mask
        id="mask"
        x="568.7"
        y="-74"
        width="128.3"
        height="176"
        maskUnits="userSpaceOnUse"
      >
        <g id="mask-2">
          <path
            id="path-11"
            data-name="path-1"
            d="M606.8,79l3.1,8.2,8.2,3.2c-2.6,1.4-5.6,2-8.2,3.4-1,2.6-2,5.2-3,7.8,0,.2,0,.5-.3.4-.4-.1-2.6-7.3-3.2-8.2l-8-3.1-.4-.4,8.2-3.1,3.1-7.9.5-.3ZM634,18c3.7,8.7,6.8,17.6,10.3,26.4l26.6,10c.2.2,0,.3-.2.4-.5.3-1.4.5-2,.8-8.1,3.2-16.4,6-24.5,9.2-3.3,8.2-6.2,16.6-9.5,24.8,0,.2-.6,1.5-.7,1.5-3.6-8.7-6.8-17.5-10.2-26.3l-26.8-10.1,1.8-.9,25-9.4,9.9-25.8.3-.5ZM597,0c2,4.4,3.4,9.2,5.4,13.6,4.5,1.9,9.1,3.5,13.6,5.2,0,.3-.1.4-.4.5h-.2c0,0-.1,0-.1.1-4.2,1.8-8.7,3.1-12.9,4.8-1.7,4-3,8.1-4.6,12.1-.1.3-.6,1.6-.8,1.6l-5.4-13.7-13.6-5.2c0-.4.1-.2.3-.3,4.2-2,9.2-3.1,13.3-5.2l5.3-13.5Z"
            style="fill: #fff; fill-rule: evenodd"
          />
        </g>
      </mask>
      <linearGradient
        id="linear-gradient"
        x1="30.3"
        y1="501.4"
        x2="30.3"
        y2="500.4"
        gradientTransform="translate(-3250 88165) scale(128.3 -176)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0" stop-color="#eee" />
        <stop offset="1" stop-color="#d8d8d8" />
      </linearGradient>
      <linearGradient
        id="linear-gradient1"
        x1="29.8"
        y1="500.9"
        x2="30.8"
        y2="500.9"
        gradientTransform="translate(-3250 88165) scale(128.3 -176)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0" stop-color="#ace7fe" />
        <stop offset=".5" stop-color="#c296ff" />
        <stop offset="1" stop-color="#f69aaa" />
      </linearGradient>
    </defs>
    <g id="Page-1">
      <g id="Artboard">
        <g id="Group">
          <g id="replaca" style="isolation: isolate">
            <g style="isolation: isolate">
              <path
                d="M42.4,78.7c4.4-2.5,9.4-3.8,15.1-3.8v22.3h-5.6c-6.7,0-11.7,1.6-15.1,4.7-3.4,3.1-5.1,8.6-5.1,16.4v41.8H10.6v-83.9h21.2v13c2.7-4.4,6.3-7.9,10.7-10.4Z"
                style="fill: #fff"
              />
              <path
                d="M149.1,124.4h-61.3c.5,6.1,2.6,10.8,6.4,14.2,3.7,3.4,8.3,5.1,13.8,5.1,7.9,0,13.5-3.4,16.8-10.1h22.9c-2.4,8.1-7.1,14.7-13.9,19.9-6.9,5.2-15.3,7.8-25.3,7.8s-15.3-1.8-21.7-5.4c-6.4-3.6-11.4-8.7-15-15.2-3.6-6.6-5.4-14.1-5.4-22.7s1.8-16.3,5.3-22.9c3.5-6.6,8.5-11.6,14.8-15.1,6.4-3.5,13.7-5.3,22-5.3s15.1,1.7,21.4,5.1c6.3,3.4,11.2,8.3,14.7,14.6,3.5,6.3,5.2,13.5,5.2,21.7s-.2,5.8-.6,8.2ZM127.8,110.2c-.1-5.5-2.1-9.8-5.9-13.1-3.8-3.3-8.5-4.9-14.1-4.9s-9.7,1.6-13.2,4.8c-3.6,3.2-5.8,7.6-6.6,13.2h39.8Z"
                style="fill: #fff"
              />
              <path
                d="M197.6,78.6c4.8-2.6,10.3-3.9,16.4-3.9s13.6,1.8,19.5,5.3c5.8,3.5,10.4,8.6,13.8,15.1,3.4,6.5,5.1,14.1,5.1,22.6s-1.7,16.2-5.1,22.8c-3.4,6.6-8,11.7-13.8,15.4-5.8,3.6-12.3,5.5-19.5,5.5s-11.6-1.3-16.3-3.8c-4.7-2.5-8.5-5.7-11.4-9.5v51.9h-21.2v-123.8h21.2v12.1c2.7-3.8,6.5-7,11.3-9.6ZM227.6,104.7c-2.1-3.7-4.8-6.5-8.2-8.4-3.4-1.9-7-2.9-11-2.9s-7.4,1-10.8,3c-3.4,2-6.1,4.8-8.2,8.6-2.1,3.7-3.1,8.1-3.1,13.2s1,9.4,3.1,13.2c2.1,3.7,4.8,6.6,8.2,8.6,3.4,2,7,3,10.8,3s7.6-1,11-3c3.4-2,6.1-4.9,8.2-8.6,2.1-3.7,3.1-8.2,3.1-13.3s-1-9.4-3.1-13.1Z"
                style="fill: #fff"
              />
              <path d="M289,48v112h-21.2V48h21.2Z" style="fill: #fff" />
              <path
                d="M309.5,95.2c3.4-6.6,8-11.6,13.8-15.1,5.8-3.5,12.3-5.3,19.5-5.3s11.7,1.3,16.4,3.8c4.7,2.5,8.5,5.7,11.3,9.5v-12h21.3v83.9h-21.3v-12.3c-2.7,3.9-6.5,7.2-11.3,9.8-4.8,2.6-10.3,3.9-16.6,3.9s-13.5-1.8-19.3-5.5c-5.8-3.6-10.4-8.8-13.8-15.4-3.4-6.6-5.1-14.2-5.1-22.8s1.7-16,5.1-22.6ZM367.4,104.8c-2-3.7-4.7-6.5-8.2-8.5-3.4-2-7.1-3-11.1-3s-7.6,1-10.9,2.9c-3.3,1.9-6,4.7-8.1,8.4-2.1,3.7-3.1,8.1-3.1,13.1s1,9.5,3.1,13.2c2.1,3.8,4.8,6.7,8.2,8.7,3.4,2,7,3,10.8,3s7.6-1,11.1-3c3.4-2,6.2-4.8,8.2-8.5,2-3.7,3-8.1,3-13.2s-1-9.6-3-13.2Z"
                style="fill: #fff"
              />
              <path
                d="M412.4,95.3c3.5-6.5,8.4-11.6,14.7-15.1,6.3-3.6,13.4-5.4,21.5-5.4s19,2.6,25.8,7.8c6.8,5.2,11.4,12.5,13.7,21.9h-22.9c-1.2-3.6-3.3-6.5-6.1-8.6-2.9-2.1-6.4-3.1-10.7-3.1-6.1,0-10.9,2.2-14.4,6.6-3.5,4.4-5.3,10.6-5.3,18.7s1.8,14.2,5.3,18.5c3.5,4.4,8.3,6.6,14.4,6.6,8.6,0,14.2-3.8,16.8-11.5h22.9c-2.3,9.1-6.9,16.3-13.8,21.7-6.9,5.4-15.4,8-25.7,8s-15.2-1.8-21.5-5.4c-6.3-3.6-11.2-8.6-14.7-15.1-3.5-6.5-5.3-14.1-5.3-22.8s1.8-16.3,5.3-22.8Z"
                style="fill: #fff"
              />
              <path
                d="M503.3,95.2c3.4-6.6,8-11.6,13.8-15.1,5.8-3.5,12.3-5.3,19.5-5.3s11.7,1.3,16.4,3.8c4.7,2.5,8.5,5.7,11.3,9.5v-12h21.3v83.9h-21.3v-12.3c-2.7,3.9-6.5,7.2-11.3,9.8-4.8,2.6-10.3,3.9-16.6,3.9s-13.5-1.8-19.3-5.5c-5.8-3.6-10.4-8.8-13.8-15.4-3.4-6.6-5.1-14.2-5.1-22.8s1.7-16,5.1-22.6ZM561.2,104.8c-2-3.7-4.7-6.5-8.2-8.5-3.4-2-7.1-3-11.1-3s-7.6,1-10.9,2.9c-3.3,1.9-6,4.7-8.1,8.4-2.1,3.7-3.1,8.1-3.1,13.1s1,9.5,3.1,13.2c2.1,3.8,4.8,6.7,8.2,8.7,3.4,2,7,3,10.8,3s7.6-1,11.1-3c3.4-2,6.2-4.8,8.2-8.5,2-3.7,3-8.1,3-13.2s-1-9.6-3-13.2Z"
                style="fill: #fff"
              />
            </g>
          </g>
          <g id="Group-8">
            <g id="Fill-7">
              <path
                id="path-1"
                d="M606.8,79l3.1,8.2,8.2,3.2c-2.6,1.4-5.6,2-8.2,3.4-1,2.6-2,5.2-3,7.8,0,.2,0,.5-.3.4-.4-.1-2.6-7.3-3.2-8.2l-8-3.1-.4-.4,8.2-3.1,3.1-7.9.5-.3ZM634,18c3.7,8.7,6.8,17.6,10.3,26.4l26.6,10c.2.2,0,.3-.2.4-.5.3-1.4.5-2,.8-8.1,3.2-16.4,6-24.5,9.2-3.3,8.2-6.2,16.6-9.5,24.8,0,.2-.6,1.5-.7,1.5-3.6-8.7-6.8-17.5-10.2-26.3l-26.8-10.1,1.8-.9,25-9.4,9.9-25.8.3-.5ZM597,0c2,4.4,3.4,9.2,5.4,13.6,4.5,1.9,9.1,3.5,13.6,5.2,0,.3-.1.4-.4.5h-.2c0,0-.1,0-.1.1-4.2,1.8-8.7,3.1-12.9,4.8-1.7,4-3,8.1-4.6,12.1-.1.3-.6,1.6-.8,1.6l-5.4-13.7-13.6-5.2c0-.4.1-.2.3-.3,4.2-2,9.2-3.1,13.3-5.2l5.3-13.5Z"
                style="fill: #fff; fill-rule: evenodd"
              />
            </g>
            <g style="mask: url(#mask)">
              <g id="Rectangle">
                <polygon
                  id="path-5"
                  points="568.7 8 697 -74 697 102 591.7 102 568.7 8"
                  style="fill: url(#linear-gradient); fill-rule: evenodd"
                />
                <polygon
                  id="path-51"
                  data-name="path-5"
                  points="568.7 8 697 -74 697 102 591.7 102 568.7 8"
                  style="fill: url(#linear-gradient1); fill-rule: evenodd"
                />
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
</template>
