<script setup lang="ts">
import { formatTimeAgo } from "@vueuse/core";

const { isChatSlideoverOpen } = useDashboard();

const userInput = ref("");
const messages = ref([
  {
    type: "user",
    text: "Create two new locations please",
  },
  {
    type: "agent",
    text: "The new location I think should be about a beautiful scenery. Maybe A tropical beach with ocean water and a waterfall and maybe some coconut trees. Does that sound good?",
    choices: ["YES", "No, cancel"],
  },
  {
    type: "agent",
    text: "I've created the new Location, You can find it under locations. Enjoy",
    images: [
      {
        src: "https://picsum.photos/seed/beach1/400/300",
        alt: "Blue Lagoon Beach",
        title: "Blue Lagoon Beach",
      },
      {
        src: "https://picsum.photos/seed/beach2/400/300",
        alt: "Punta Cana Beach",
        title: "Punta Cana Beach",
      },
    ],
  },
  {
    type: "user",
    text: "Apply them to one of our female models with a white shirt",
  },
  {
    type: "agent",
    text: "I've used Jessi with a white shirt on the tropical beach. The image is lookated in your lookbook.",
    images: [
      {
        // Using a specific beach/sunset image from Picsum
        src: "https://picsum.photos/seed/model/300/400",
        alt: "Model on beach",
        title: "Model on beach",
      },
    ],
  },
]);

const sendMessage = () => {
  if (userInput.value.trim()) {
    messages.value.push({
      type: "user",
      text: userInput.value,
    });
    userInput.value = "";
  }
};
</script>

<template>
  <UDashboardSlideover v-model="isChatSlideoverOpen" title="Grain Agentic">
    <div class="flex flex-col h-screen text-gray-300">
      <!-- Chat Container -->
      <div ref="chatContainer" class="flex-1 p-4 space-y-6">
        <div class="max-w-2xl mx-auto flex flex-col justify-end min-h-full">
          <!-- Messages -->
          <div class="space-y-6">
            <div
              v-for="(message, index) in messages"
              :key="index"
              class="space-y-6"
            >
              <!-- Message -->
              <div
                :class="[
                  'rounded-lg p-4',
                  message.type === 'user'
                    ? 'bg-[#17191A] flex gap-4'
                    : 'bg-[#2A2A2A]',
                ]"
              >
                <!-- User Avatar Space -->
                <div
                  v-if="message.type === 'user'"
                  class="w-8 h-8 rounded-full bg-gray-700/50 flex-shrink-0"
                >
                  <UAvatar alt="Evelina Gotfredsen" size="sm" class="mb-8" />
                </div>

                <!-- Message Content -->
                <div class="flex-1">
                  <p class="text-[15px] leading-relaxed font-light">
                    {{ message.text }}
                  </p>

                  <!-- Choices -->
                  <div
                    v-if="message.choices"
                    class="mt-4 grid grid-cols-2 gap-4"
                  >
                    <button
                      v-for="choice in message.choices"
                      :key="choice"
                      :class="`px-4 py-2 rounded text-sm font-light border ${
                        choice === 'YES'
                          ? 'gradient-border hover:bg-pink-500/10'
                          : 'border-gray-600/50 text-gray-400 hover:bg-gray-700/50'
                      }`"
                    >
                      {{ choice }}
                    </button>
                  </div>

                  <!-- Images Grid -->
                  <div
                    v-if="message.images"
                    class="mt-4 grid grid-cols-2 gap-4"
                  >
                    <div
                      v-for="image in message.images"
                      :key="image.title"
                      class="relative rounded-lg overflow-hidden group"
                    >
                      <img
                        :src="image.src"
                        :alt="image.alt"
                        class="w-full h-40 object-cover"
                      />
                      <div
                        class="absolute bottom-0 left-0 right-0 p-2 bg-black/50 text-[13px] font-light text-gray-300"
                      >
                        {{ image.title }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <template #footer>
      <!-- Input Area -->
      <div class="m-4 relative w-full">
        <UInput
          variant="none"
          v-model="userInput"
          placeholder="Enter you fashion dream here"
          size="lg"
          class="w-full gradient-border rounded-xl relative"
        >
          <template #trailing>
            <span
              class="rounded-full gradient-border text-white-500 dark:text-black !text-xs !py-1 !px-3 !ml-0 !mr-0 !mt-0 inline-flex cursor-pointer"
            >
              <span>Send</span>
            </span>
          </template></UInput
        >
      </div>
    </template>
  </UDashboardSlideover>
</template>
