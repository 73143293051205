<script setup>
import { useInfiniteQuery, useQueryClient } from "@tanstack/vue-query";
import { useVirtualizer } from "@tanstack/vue-virtual";
import { animations } from "@formkit/drag-and-drop";
import { dragAndDrop } from "@formkit/drag-and-drop/vue";
//import { fixSentry, fixhandleTouchstart } from "~/utils/fixSentry";
//import { handleDragstart } from "@formkit/drag-and-drop";
import { useDropZone } from "@vueuse/core";

const { isGarnmentSlideoverOpen } = useDashboard();

const uploadingImg = ref(false);

const {
  public: { api_url },
} = useRuntimeConfig();

const upload = useUpload(`${api_url}/products/upload-image`, {
  multiple: true,
  credentials: "include",
});

const listRef = ref(null);

const queryClient = useQueryClient();

// Infinite query for fetching garments data
const { data, fetchNextPage, hasNextPage, isFetchingNextPage, isFetching } =
  useInfiniteQuery({
    queryKey: ["garments"],
    queryFn: ({ pageParam }) =>
      $fetch(
        `${api_url}/products/items?limit=30${
          pageParam ? `&cursor=${pageParam}` : ""
        }`,
        {
          credentials: "include",
        }
      ),
    getNextPageParam: (lastPage) => lastPage.nextPage, // Use the `nextPage` cursor from the backend
    refetchOnMount: false,
  });

/*const garmentsRef = computed(() =>
  data.value ? data.value.pages.flatMap((d) => d.garments) : []
);*/

const garmentsRef = ref([]); // Mutable ref to store images

// Update the images ref with the data fetched from the query
watch(data, (newData) => {
  if (newData) {
    garmentsRef.value = newData.pages.flatMap((d) => d.garments); // Store images in the ref
  }
});

// TanStack Virtual setup
const parentRef = ref(null);
const columns = 3;
const columnCount = 3; // Number of columns per row
const rowHeight = 250;
const rowVirtualizerOptions = computed(() => ({
  count: Math.ceil(garmentsRef.value.length),
  getScrollElement: () => parentRef.value,
  estimateSize: () => 240,
  lanes: columns,
  overscan: 12,
}));

const rowVirtualizer = useVirtualizer(rowVirtualizerOptions);

const virtualRows = computed(() => rowVirtualizer.value.getVirtualItems());

const totalSizeRows = computed(() => rowVirtualizer.value.getTotalSize());

// Watch the virtual rows and trigger fetching more data when near the bottom

watchEffect(() => {
  // Check the last visible row
  const lastVisibleRow = virtualRows.value[virtualRows.value.length - 1];

  // Ensure that the last row is near the end of the data set and fetch more data if necessary
  if (
    lastVisibleRow &&
    lastVisibleRow.index >= Math.floor(garmentsRef.value.length) - 1 &&
    hasNextPage &&
    !isFetchingNextPage.value
  ) {
    fetchNextPage();
  }
});

const dropZoneRef = ref(null);
const fileInput = ref(null);

const { isOverDropZone } = useDropZone(dropZoneRef, onDrop);

async function uploadImageNew(image, filter = false) {
  rowVirtualizer.value.scrollToIndex(0); // Refetch after upload

  await upload(image).catch((err) =>
    toast.add({
      color: "red",
      title: "Failed to upload image",
      description: err.data?.message || err.message,
    })
  );

  queryClient.invalidateQueries(["garments"]);
}

async function onDrop(files) {
  files && (await uploadFile(files));
}

async function uploadFile(files) {
  uploadingImg.value = true;
  await uploadImageNew(files)
    .catch(() =>
      toast.add({
        title: "An error occurred",
        description: "Please try again",
        color: "red",
      })
    )
    .finally(() => (uploadingImg.value = false));
}

function openFilePicker() {
  fileInput.value?.click();
}

/*const virtualItems = computed(() => {
  const items = [];
  rowVirtualizer.value.getVirtualItems().forEach((virtualRow) => {
    for (let column = 0; column < columns; column++) {
      const index = virtualRow.index * columns + column;
      if (index < garmentsRef.value.length) {
        items.push({
          row: virtualRow.index,
          column,
          top: virtualRow.start,
          left: (column * 100) / columns + "%", // Calculate left position based on column index
          width: `calc(100% / ${columns} - 12px)`, // Adjust for spacing
          height: rowHeight,
          item: garmentsRef.value[index], // Access the actual item from the data
        });
      }
    }
  });
  return items;
});*/

const virtualItems = computed(() => {
  const items = [];
  rowVirtualizer.value.getVirtualItems().forEach((virtualRow) => {
    const index = virtualRow.index;
    if (index < garmentsRef.value.length) {
      items.push(
        garmentsRef.value[index] // Access the actual item from the data
      );
    }
  });
  return items;
});

const { messages } = useWebSocket();

// Watch for incoming WebSocket messages
/*watch(messages, (newMessages) => {
  alert(JSON.stringify(newMessages));
  for (const message of newMessages) {
    if (message.event === "garment_processed") {
      const processedGarment = garmentsRef.value.find(
        (garment) => garment.id === message.productId
      );
      if (processedGarment) {
        processedGarment.status = "processed";
        processedGarment.processed_image_url = message.imageUrl;
      }
    }
  }
});*/

/*watch(messages, (newMessages) => {
  alert(JSON.stringify(newMessages));
  for (const message of newMessages) {
    if (message.event === "garment_processed") {
      // Loop through the pages and find the garment to update
      data.value.pages.forEach((page) => {
        const garment = page.garments.find(
          (garment) => garment.id === message.productId
        );

        // If the garment is found, update its status and processed image URL
        if (garment) {
          alert("found");
          alert(garment.status);
          garment.status = "processed";
          alert(garment.status);
          garment.processed_image_url = message.imageUrl;
        }
      });
    }
  }
});*/

watch(messages, (newMessages) => {
  for (const message of newMessages) {
    if (message.event === "garment_processed") {
      // Update the query cache locally (no refetch)
      queryClient.setQueryData(["garments"], (oldData) => {
        if (!oldData) return oldData; // Return if no existing data

        // Copy old data to ensure immutability
        const newData = { ...oldData, pages: [...oldData.pages] };

        // Update the specific garment in the appropriate page
        newData.pages = newData.pages.map((page) => ({
          ...page,
          garments: page.garments.map((garment) => {
            if (garment.id === message.productId) {
              // Modify only the relevant garment
              return {
                ...garment,
                status: "processed", // Update status
                processed_image_url: message.imageUrl, // Update image URL
              };
            }
            return garment; // Return unchanged garments
          }),
        }));

        return newData; // Return updated data (in-memory only)
      });
    }
  }
});

const visibleItems = ref([]);

// Watch for changes in virtualRows and garmentsRef to update visibleItems
watch([virtualRows, garmentsRef], () => {
  visibleItems.value = virtualRows.value.map(
    (row) => garmentsRef.value[row.index]
  );
});

// Drag and Drop initialization
const initializeDragAndDrop = () => {
  dragAndDrop({
    parent: listRef,
    values: visibleItems,
    plugins: [animations()],
    group: "items",
    onDragstart: () => {
      isGarnmentSlideoverOpen.value = false;
    },
    /* handleDragstart: function (event) {
      isGarnmentSlideoverOpen.value = false;
      handleDragstart(event);
    },
    handleTouchstart: function (event) {
      isGarnmentSlideoverOpen.value = false;
      fixhandleTouchstart(event);
    },*/
    draggable: (el) => el.id !== "no-drag",
  });
};

watch(isGarnmentSlideoverOpen, (newVal) => {
  if (newVal) initializeDragAndDrop();
});

onMounted(() => {
  if (data.value) {
    garmentsRef.value = data.value.pages.flatMap((d) => d.garments);
  }

  initializeDragAndDrop(); // Initialize drag-and-drop on mount
});

const items = [
  [
    {
      label: "All garnments",
    },
    {
      label: "T-Shirts",
    },
    {
      label: "Shirts",
    },
  ],
];
</script>

<template>
  <UDashboardSlideover
    :ui="{
      background: ' dark:bg-[#28282a]',
      body: { base: 'overflow-hidden relative' },
    }"
    v-model="isGarnmentSlideoverOpen"
    title="Garnments"
  >
    <template #header>
      <UButton
        color="gray"
        variant="ghost"
        size="sm"
        icon="i-heroicons-arrow-left-20-solid"
      />
      <UDropdown :items="items" :popper="{ arrow: true }">
        ALL GARNMENTS
      </UDropdown>
      <UButton
        color="gray"
        variant="ghost"
        size="sm"
        icon="i-heroicons-plus-20-solid"
      />
    </template>

    <div class="container mx-auto py-0 h-full" ref="dropZoneRef">
      <div
        class="container mx-auto py-0 no-scrollbar"
        ref="parentRef"
        style="width: 100%; height: 100%; overflow-y: auto"
      >
        <div
          :style="{
            height: `${totalSizeRows}px`,
            width: '100%',
            position: 'relative',
          }"
          ref="listRef"
        >
          <div
            v-for="virtualRow in virtualRows"
            :key="garmentsRef[virtualRow.index]?.id || virtualRow.index"
            class="relative"
            :style="{
              position: 'absolute',
              top: `${virtualRow.start}px`,
              left: `${virtualRow.lane * 33}%`,
              width: '31.8%',
              height: `${virtualRow.size}px`,
              // transform: 'translate3d(0, 0, 0)', // This line adds GPU acceleration
              // willChange: 'transform', // This line hints the browser to optimize
            }"
          >
            <UCard
              v-if="garmentsRef[virtualRow.index]"
              :class="{
                processing:
                  garmentsRef[virtualRow.index]?.status !== 'unprocessed',
              }"
              :ui="{
                background: 'dark:bg-[#1e1f21]',
                ring: 'ring-0',
                base: 'rounded-lg overflow-hidden relative',
                body: 'px-0 py-0',
              }"
            >
              <div class="glowtwo wipe"></div>
              <div class="glowspark"></div>
              <div class="h-40">
                <img
                  draggable="false"
                  :src="
                    garmentsRef[virtualRow.index]?.status === 'processed'
                      ? garmentsRef[virtualRow.index]?.processed_image_url
                      : garmentsRef[virtualRow.index]?.original_image_url
                  "
                  :alt="garmentsRef[virtualRow.index]?.name"
                  loading="eager"
                  :class="{
                    'object-cover p-0':
                      garmentsRef[virtualRow.index]?.status !== 'processed',
                    'object-contain p-4':
                      garmentsRef[virtualRow.index]?.status === 'processed',
                    'w-full h-full dark:bg-[#1e1f21]': true,
                  }"
                />
              </div>
              <template #footer>
                <div class="p-0">
                  <p class="text-gray-600 text-xs">
                    {{ garmentsRef[virtualRow.index]?.status }}
                  </p>
                  <h3 class="text-[9px] font-semibold">
                    {{ virtualRow.index }} {{ virtualRow.lane }}
                    {{ garmentsRef[virtualRow.index]?.name }}
                  </h3>
                </div>
              </template>
            </UCard>
          </div>
        </div>

        <div v-if="isFetchingNextPage" class="loading">Loading more...</div>
      </div>
      <div
        id="no-drag"
        class="z-20 absolute top-0 p-8 left-0 w-full h-full dark:bg-[#28282a]"
        v-if="isOverDropZone && false"
      >
        <input
          ref="fileInput"
          class="hidden"
          type="file"
          accept="image/*"
          @change="fileSelection"
        />
        <UploadButton2
          :uploading="uploadingImg"
          type="submit"
          class="mb-2 w-full h-full"
          :is-over-drop-zone="isOverDropZone"
          @click="openFilePicker"
        />
      </div>
    </div>
  </UDashboardSlideover>
</template>

<style scoped lang="postcss">
.dragging {
  border: 2px dashed #4b5563 !important;
  background-color: #2d3748 !important;
  border-radius: 0.375rem !important;
  transition: all 0.2s ease !important;
}
.dragfly {
  transform: scale(1.25) !important;
  transition: all 0.2s ease !important;
}

.selected-card {
  border: 1px solid #4b5563; /* Customize the color as needed */
}
.gradient-border-anim {
  --borderWidth: 1px;
  background: #1d1f20;
  position: relative;
  border-radius: var(--borderWidth);
}
.gradient-border-anim:after {
  content: "";
  position: absolute;
  top: calc(-1 * var(--borderWidth));
  left: calc(-1 * var(--borderWidth));
  height: calc(100% + var(--borderWidth) * 2);
  width: calc(100% + var(--borderWidth) * 2);
  background: linear-gradient(
    60deg,
    #f79533,
    #f37055,
    #ef4e7b,
    #a166ab,
    #5073b8,
    #1098ad,
    #07b39b,
    #6fba82
  );
  border-radius: calc(2 * var(--borderWidth));
  z-index: -1;
  animation: animatedgradient 3s ease alternate infinite;
  background-size: 300% 300%;
}

@keyframes animatedgradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

.glowcontainer {
  width: 300px;
  height: 450px;
  background: gray;

  position: relative;
}
.glowspark {
  border-radius: 5px;
  overflow: hidden;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0px;
  left: 0px;
  filter: blur(9px);
  mix-blend-mode: multiply;
  opacity: 1;
  transition: all 0.5s;
}

.processing .glowspark {
  opacity: 0;
}

.glowspark:after {
  content: "";
  position: absolute;
  background: #fff;
  inset: 10px;
  border-radius: 5px;
}

.glowspark::before {
  content: "";
  width: 200%;

  aspect-ratio: 1;
  inset: 50% auto auto 50%;
  position: absolute;
  translate: -50% -50%;
  animation: lazy 6s infinite linear;
  background: conic-gradient(
    transparent,
    rgba(0, 0, 255, 1) 90deg,
    transparent 180deg
  );
}

@keyframes lazy {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.glowtwo {
  width: 400px;
  height: 250px;
  border-radius: 5px;
  overflow: hidden;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0px;
  left: 0px;

  box-shadow: inset 0 0 50px rgba(255, 0, 255, 0.5),
    inset 0 0 100px rgba(0, 0, 255, 0.5), inset 0 0 150px rgba(255, 0, 255, 0.5);

  transition: all 0.5s;
}

.processing .glowtwo {
  box-shadow: inset 0 0 0px rgba(255, 0, 255, 0.5),
    inset 0 0 0px rgba(0, 0, 255, 0.5), inset 0 0 0px rgba(255, 0, 255, 0.5);
}

.glowtwo:before {
  content: "";
  position: absolute;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  filter: blur(20px);
  border: 15px solid;
  border-image: linear-gradient(
      90deg,
      rgba(255, 0, 255, 1),
      rgba(0, 0, 255, 1),
      rgba(255, 0, 255, 1)
    )
    10;

  transition: all 0.5s;
}

.processing .glowtwo:before {
  border: 0;
}

.glow {
  width: 800px;
  height: 450px;
  background: black;
  overflow: hidden;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0px;
  left: 0px;

  box-shadow: inset 0 0 50px rgba(255, 0, 255, 0.5),
    inset 0 0 100px rgba(0, 0, 255, 0.5), inset 0 0 150px rgba(255, 0, 255, 0.5);
}

.glow:before {
  content: "";
  position: absolute;
  top: -10px;
  left: -10px;
  right: -10px;
  bottom: -10px;
  background: linear-gradient(
    90deg,
    rgba(255, 0, 255, 1),
    rgba(0, 0, 255, 1),
    rgba(255, 0, 255, 1)
  );
  z-index: 1;
  filter: blur(30px);
}

.glow:after {
  content: "";
  position: absolute;
  top: 5%;
  left: 5%;
  right: 5%;
  bottom: 5%;
  background: black;
  filter: blur(50px);
  z-index: 2;
}

.wipe {
  transition: all 600ms cubic-bezier(0, 0.55, 0.45, 1);
}
</style>
