<script setup>
// Import other components as needed

const currentView = ref("UserForm");
const props = defineProps({
  modelValue: {
    type: Boolean,
    required: false,
  },
});
const route = useRoute();
const emit = defineEmits(["update:modelValue"]);
const isOpen = computed({
  get: () => props.modelValue,
  set: (value) => emit("update:modelValue", value),
});

const links = ref([
  {
    label: "Settings",
    icon: "i-heroicons-cog-8-tooth",
    active: true,
    click: () => {
      currentView.value = "UserForm";
      setActiveLink("Settings");
    },
  },
  {
    label: "Subscription Overview",
    icon: "i-heroicons-document-text",
    active: false,
    click: () => {
      currentView.value = "SubscriptionOverview";
      setActiveLink("Subscription Overview");
    },
  },
  {
    label: "Payment Details",
    icon: "i-heroicons-credit-card",
    active: false,
    click: () => {
      currentView.value = "PaymentDetails";
      setActiveLink("Payment Details");
    },
  },
  {
    label: "Invoices",
    icon: "i-heroicons-document-currency-dollar",
    active: false,
    click: () => {
      currentView.value = "Invoices";
      setActiveLink("Invoices");
    },
  },
]);

function setActiveLink(label) {
  links.value.forEach((link) => {
    link.active = false;
  });
  const activeLink = links.value.find((link) => link.label === label);
  if (activeLink) {
    activeLink.active = true;
  }
}
</script>

<template>
  <UModal
    v-model="isOpen"
    class="flex items-center justify-center"
    :ui="{
      width: 'w-full sm:max-w-3xl',
      overlay: {
        background: 'bg-black/75 dark:bg-black/75 backdrop-blur',
      },
    }"
  >
    <div
      class="rounded-xl relative flex h-full w-full overflow-hidden shadow-[0px_14px_34px_#000000]"
    >
      <UDashboardLayout
        :ui="{ wrapper: 'relative inset-0 flex w-full overflow-hidden' }"
      >
        <UDashboardPanel :width="250" :ui="{ border: 'border-r-0' }">
          <UDashboardNavbar title="User Settings" />

          <UDashboardSidebar :width="350">
            <UDashboardSidebarLinks :links="links" :ui="{ base: 'py-3' }" />
          </UDashboardSidebar>
        </UDashboardPanel>
        <UDashboardPanel
          grow
          :ui="{
            wrapper: 'w-full shadow-[0_14px_34px_rgba(0,0,0,1)]',
            border: 'border-r-0',
          }"
        >
          <UDashboardPanelContent>
            <component :is="currentView" />
          </UDashboardPanelContent>
        </UDashboardPanel>
      </UDashboardLayout>
    </div>
    <UButton
      icon="i-heroicons-x-mark"
      class="absolute right-4 top-4"
      @click="isOpen = false"
    />
  </UModal>
</template>
